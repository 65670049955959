@import "theme";

.referenzen-container {
    @include center;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 30vh;
    margin-top: 10vh;
}

.referenzen-item {
    @include center;
    width: 90vw;
    max-width: 900px;
    min-height: 20vh;
    margin-top: 2vh;
    margin-bottom: 2vh;
    flex-direction: column;
    background-color: #f2f2f2;
}

.referenzen-item-text {
    @include center;
    width: 84vw;
    max-width: 800px;
    min-height: 15vh;
    flex-grow: 5;
    font-size: larger;
    background-color: #f2f2f2;
}

.referenzen-item-author {
    @include center;
    width: 90vw;
    max-width: 900px;
    height: 5vh;
    flex-grow: 1;
    font-size: larger;
    font-weight: bold;
    background-color: #d8d8d8;
}