@import "theme";

html, body {
  overflow-x: hidden;
}

.App {
  max-width: 100%;
  height: 100%;
  margin: 0;
  text-align: center;
  
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* hide scrollbar for chrome, safari and opera */
.App::-webkit-scrollbar {
  display: none;
}

.navbar{
  max-width: 100%;
  height: 10vh;
  background-color: $purple;
}

.header {
  min-height: 60vh;
  max-width: 100%;
}

.body {
  max-width: 100%;
}

.footer {
  margin-top: 10vh;
  @include center;
  max-width: 100%;
  min-height: 10vh;
  background-color: $purple;
  font-size: smaller;
}


.menu {
  z-index: 1000;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  position: fixed;
  top: 0px;
  left: 0px;
}

.menu-esc-container {
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
  height: 10vh;
  background-color: $purple;
  margin-bottom: 10vh;
}

.menu-esc {
  @include center;
  @include clickable;
  width: 10vh;
  height: 10vh;
  background-color: $purple;
}

.menu-items-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: 90vh;
}

.menu-item {
  @include center;
  @include clickable;
  font-size: xx-large;
  max-width: 100%;
  height: 10vh;
  margin-bottom: 1vh;
}


.image {
  max-width: 100%;
  max-height: 100%;
}

.text-block {
  @include center;
  flex-direction: column;
  margin-top: 4vh;
  margin-bottom: 4vh;
  font-size: xx-large;
  padding: 4vw;
}

.small-img {
  margin-top: 3vh;
  width: 8vh;
  height: 8vh;
}

.title-block {
  @include center;
  flex-direction: column;
  margin-top: 5vh;
  margin-bottom: 5vh;
  font-size: xx-large;
  font-weight: bolder;
  max-width: 100%;
  height: 30vh;
  background-color: $purple;
}

.categories-block {
  margin-top: -80px;
  @include center;
  flex-wrap: wrap;
  max-width: 100%;
}

.category {
  @include center;
  justify-content: flex-start;
  flex-direction: column;
  min-width: 400px;
  width: 25vw;
  min-height: 400px;
  margin-bottom: 5vh;
  margin: 1vh 1vw;
  background: rgba(255, 226, 160, 0.7);
  transition: height 5s ease-in-out;
  transform-origin: top;
}

.category-title {
  @include center;
  flex-direction: column;
  font-size: x-large;
  font-weight: bold;
  min-width: 400px;
  width: 25vw;
  height: 80px;
  background-color: $purple2;
}

.category-img {
  position: relative;
  min-width: 400px;
  width: 25vw;
  min-height: 400px;
  height: 25vw;
}

.category-text {
  position: absolute;
  padding: 30px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 226, 160, 0.7);
  color: #000000;
  visibility: hidden;
  opacity: 0;
  font-size: medium;

  /* transition effect. not necessary */
  transition: opacity .2s, visibility .2s;
}

.category:hover .category-text {
  visibility: visible;
  opacity: 1;
}

.category:nth-child(1):hover {
  height: 650px;
}

.category:nth-child(2):hover {
  height: 750px;
}

.category:nth-child(3):hover {
  height: 1000px;
}

@media (max-width: 800px) { /*choose the width you prefer*/
  .header {
    min-height: auto;
  }
}
