@import "theme";

.kontakt-container {
    @include center;
    flex-direction: column;
    max-width: 100%;
    margin-bottom: 5vh;
}

.kontakt {
    @include center;
    justify-content: space-between;
    width: 90vw;
    max-width: 900px;
    min-height: 30vh;
    margin-bottom: 1vh;
    background-color: #f2f2f2;
}

.kontakt-img {
    height: 30vh;
}

.kontakt-daten {
    @include center;
    width: 30vh;
    height: 30vh;
    align-self: center;
    font-size: large;
    font-weight: bold;
}

.kontakt-text {
    @include center;
    flex-direction: column;
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-size: larger;
    padding: 1vh;
}

@media (max-width: 900px) { /*choose the width you prefer*/
    .kontakt {
        @include center;
        flex-direction: column;
        justify-content: space-between;
        width: 70vw;
        max-width: 900px;
        min-height: 30vh;
        margin-bottom: 1vh;
        background-color: #f2f2f2;
    }
  }