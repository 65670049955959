@import "theme";

.überuns-text {
    @include center;
    flex-direction: column;
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-size: larger;
    padding: 5vw;
}

.gallery-block-container {
    @include center;
}

.gallery-block {
    min-width: 800px;
    max-width: 60vw;
    @include center;
    margin-top: 2vh;
    margin-bottom: 2vh;
    flex-wrap: wrap;
}

.gallery-img {
    margin: 1vh;
    position: relative;
    min-width: 300px;
    width: 16vw;
    min-height: 300px;
    height: 16vw;
}

@media (max-width: 800px) { /*choose the width you prefer*/
    .gallery-block {
        min-width: auto;
    }
}