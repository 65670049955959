@import "theme";

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo-container {
  @include clickable;
  display: flex;
}

.navbar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100px;
  margin-left: 2vw;
  color: black;
  flex-shrink: 0;
}

.navbar-logo-img {
  max-width:100%;
  max-height:100%;
}

.navbar-list {
  display: flex;
  min-width: 50px;
}

.navbar-item {
  @include clickable;
  visibility: visible;
  font-size: small;
  margin: 0vw 1vw;
}

.navbar-fabars-button {
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10vh;
  height: 10vh;
  flex-shrink: 0;
  background-color: $purple;
  @include clickable
}

.navbar-fabars {
  visibility: hidden;
  flex-shrink: 0;
}

@media (max-width: 800px) { /*choose the width you prefer*/
  .navbar-list {
    visibility: hidden;
  }

  .navbar-item {
    visibility: hidden;
  }

  .navbar-fabars {
    visibility: visible;
  }

  .navbar-fabars-button {
    visibility: visible;
  }
}


