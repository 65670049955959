@font-face {
  src: url(../fonts/Roboto-Regular.ttf);
  font-family: 'Roboto';
}


/* Theme Colors */
  $red: #EF476F;
  $yellow: #FFD166;
  $green: #06D6A0;
  $blue: #118AB2;
  $midnight: #073B4C;
  $lightblue: #bfeff8;
  $purple: #ffc629;
  $purple2: #ffe2a0;
  
  /* some common css */
  @mixin fullscreen {
    width: 100vw;
    height: 100vh;
    margin: 0;
  }
  
  @mixin center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @mixin clickable {
    cursor: pointer;
    transition: 0.1s;
    user-select: none;
    outline: none;
    &:hover {
      filter: brightness(110%);
    }
    &:active {
      transform: scale(0.95);
    }
  }